* {
  margin: 0;
  box-sizing: border-box;
}

body {
  font-family: Arial, sans-serif;
}

.banner {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  background-color: #333;
  color: #fff;
  padding: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.banner button {
  display: block;
  margin: 10px;
}

.loading-screen {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.5em;
  color: #333;
  z-index: 1000;
}

.submit-button:disabled {
  background-color: #ccc;
  cursor: not-allowed;
}

@media (min-width: 933px) {
  .App {
    max-width: 1200px;
    margin: 0 auto;
    padding: 0 10vw;
  }

  .top-img {
    width: 90%;
  }

  .header {
    padding: 40px 0;
    background-color: #f8f8f8;
  }

  .header h1 {
    font-size: 1.6em;
    text-align: center;
    color: #333;
    margin-bottom: 20px;
  }

  .art-panel {
    display: flex;
    justify-content: center;
    padding: 40px 0;
  }

  .art-panel img {
    width: 100%;
    height: auto;
  }

  .image-text-section {
    display: flex;
    align-items: center;
    padding: 40px 0;
    margin: 40px 0;
  }

  .image-container {
    flex: 1;
    max-width: 50%;
    display: flex;
    justify-content: center;
    padding-right: 20px;
  }

  .image-container img {
    max-width: 100%;
    height: auto;
    border-radius: 10px;
  }

  .text-container {
    flex: 1;
    padding-left: 20px;
  }

  .text-container p {
    font-size: 1em;
    color: #666;
    margin-top: 10px;
  }

  .circular-text-section {
    display: flex;
    justify-content: space-around;
    align-items: center;
    padding: 40px 0;
    margin: 40px 0;
  }

  .circle {
    width: 300px;
    height: 300px;
    border: 2px solid #ddd;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    padding: 20px;
    background-color: #f0f0f0;
  }

  .circle p {
    font-size: 1.2em;
    color: #333;
    line-height: 1.4em;
  }

  .price-panel {
    width: 100%;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    margin-top: 20px;
    /* padding: 20px; */
    font-size: 1.4em;
    color: #666;
    margin-top: 10px;
  }

  .price-panel p {
    font-size: 0.8em;
  }

  .price-img {
    width: 70%;
  }

  .order-button-container {
    width: 100%;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    padding: 15px;
  }

  .order-button-container img {
    cursor: pointer;
    width: 80%;
  }

  .custom-message-section {
    text-align: center;
    padding: 40px 0;
  }

  .custom-message-section h2 {
    font-size: 2em;
    margin-bottom: 20px;
  }

  .custom-message-section p {
    font-size: 1.2em;
    margin: 10px 0 20px 0;
  }

  .custom-message-container {
    width: 100%;
    margin: 20px 0;
    border-top: 1px solid lightgray;
    border-bottom: 1px solid lightgray;
    padding: 20px;
  }

  .custom-message-info {
    display: flex;
    justify-content: space-between;
    padding: 0 20px;
    margin: 10px 0;
  }

  .custom-message-info .name,
  .custom-message-info .birthdays {
    color: orange;
    font-weight: bold;
    font-size: 1.2em;
  }

  .custom-message-info .name {
    text-align: left;
  }

  .custom-message-info .birthdays {
    text-align: right;
  }

  .material-quality-section {
    text-align: left;
    margin-top: 50px;
    padding: 10px;
  }

  .material-quality-section h2 {
    font-size: 1.8em;
    font-weight: bold;
    margin-bottom: 20px;
  }

  .quality-items {
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
  }

  .quality-item {
    width: 48%;
    text-align: left;
    box-sizing: border-box;
    margin: 20px 0px;
  }

  .quality-item img {
    width: 100%;
    height: auto;
    margin-bottom: 15px;
  }

  .quality-item h3 {
    font-size: 1.6em;
    font-weight: bold;
    margin-bottom: 10px;
  }

  .quality-item p {
    font-size: 1.2em;
    color: #555;
  }

  #footer {
    margin-bottom: 5vh;
    padding: 20px 0;
    width: 100%;
    text-align: center;
    background-color: #f8f8f8;
  }

  #footer p {
    font-size: 14px;
    color: #555;
    margin-bottom: 10px;
  }

  .order-button-container {
    width: 100%;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    padding: 15px;
  }

  .color-selection-panel {
    width: 100%;
    padding: 10px;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    display: flex;
  }

  .color-selection-img {
    width: 100%;
  }

  .size-selection-section {
    text-align: center;
    padding: 15px;
  }

  .size-selection-section h2 {
    font-size: 2.0em;
    margin: 10px 0px;
  }

  .size-selection-section p {
    font-size: 1.4em;
    margin: 10px 0;
  }

  .size-selection-img {
    margin-bottom: 20px;
    width: 100%;
    height: auto;
  }

  .video-container {
    width: 100%;
    max-width: 600px;
    margin: 0 auto;
  }

  video {
    width: 50%;
    height: auto;
  }

  .video-section {
    display: flex;
    align-items: center;
    padding: 20px;
    margin: 20px 0;
  }

  .video-container {
    flex: 1;
    padding-left: 20px;
  }

  .video-container h2 {
    font-size: 0.9em;
    font-weight: bold;
    color: #333;
  }

  .safety-section {
    display: flex;
    align-items: center;
    padding: 20px;
    margin: 20px 0;
  }

  .safety-container {
    flex: 1;
    max-width: 50%;
    display: flex;
    justify-content: center;
  }

  .safety-container img {
    max-width: 60%;
    height: auto;
    border-radius: 10px;
  }

  .safety-container {
    flex: 1;
    padding-left: 20px;
  }

  .safety-container h2 {
    font-size: 0.9em;
    font-weight: bold;
    color: #333;
  }

  .safety-container p {
    font-size: 0.8em;
    color: #666;
    margin-top: 10px;
  }

  .order-flow-section {
    text-align: center;
    /* width: 80%; */
    margin-top: 50px;
    padding: 40px 0;
  }

  .order-flow-section h2 {
    font-size: 2em;
    font-weight: bold;
    margin-bottom: 20px;
  }

  .order-flow-section h3 {
    font-size: 1.5em;
    margin-bottom: 10px;
  }

  .order-flow-section hr {
    width: 80%;
    margin: 10px auto;
    border: none;
    border-top: 1px solid #000;
  }

  .order-button-img {
    margin: 10vh 0px;
    width: 50%;
  }

  .text-container {
    flex: 1;
    padding-left: 20px;
  }

  .text-container h2 {
    font-size: 1.4em;
    font-weight: bold;
    color: #333;
  }

  .text-container p {
    font-size: 1.2em;
    color: #666;
    margin-top: 10px;
  }

  .email-recommendation {
    text-align: center;
    padding: 20px;
    margin: 10px 0px;
  }

  .email-recommendation h2 {
    font-size: 2.4em;
    line-height: 1.5;
    font-weight: bold;
  }

  .email-recommendation p {
    font-size: 1.5em;
    margin: 10px 0;
  }

  .flow-text {
    width: 40vw;
    margin: 10px 0px;
  }

  .flow-step {
    display: flex;
    align-items: center;
    margin: 10px 0;
    padding: 10px;
  }

  .flow-step-image {
    flex: 1;
  }

  .flow-step-image img {
    padding: 20px;
    width: 30vw;
    height: auto;
  }

  .flow-step-content {
    flex: 2;
    text-align: left;
  }

  .flow-step-content h4 {
    font-size: 1.6em;
    font-weight: bold;
    margin-bottom: 10px;
  }

  .flow-step-content p {
    font-size: 1.2em;
    line-height: 1.5;
  }

  .flow-step-content .time-frame {
    display: inline-block;
    background-color: black;
    color: white;
    padding: 5px 10px;
    font-size: 0.9em;
    margin-top: 10px;
    border-radius: 5px;
  }

  .contact-form {
    max-width: 600px;
    margin: 0 auto;
    padding: 20px;
    font-family: Arial, sans-serif;
  }

  .contact-form h2 {
    font-size: 2em;
    font-weight: bold;
    margin-bottom: 10px;
  }

  .required-note {
    font-size: 0.9em;
    color: #555;
    margin-bottom: 20px;
  }

  .form-group {
    margin-bottom: 20px;
  }

  .form-group label {
    display: block;
    font-weight: bold;
    margin-bottom: 5px;
  }

  .form-group input[type="text"],
  .form-group input[type="email"],
  .form-group textarea {
    width: 100%;
    padding: 15px;
    border-radius: 20px;
    border: 1px solid #ddd;
    background-color: #f7f7f7;
    font-size: 1em;
  }

  .form-group textarea {
    resize: none;
  }

  .upload-area {
    border: 2px dashed #ddd;
    border-radius: 20px;
    padding: 20px;
    text-align: center;
    background-color: #f7f7f7;
    position: relative;
  }

  .upload-button {
    cursor: pointer;
    font-weight: bold;
    background-color: #333;
    color: #fff;
    padding: 10px 20px;
    border-radius: 10px;
    display: inline-block;
    margin-top: 10px;
  }

  .submit-button {
    background-color: #333;
    color: #fff;
    font-size: 1.2em;
    border: none;
    border-radius: 30px;
    padding: 15px 30px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
  }

  .submit-button:hover {
    background-color: #555;
  }
}

@media (max-width: 932px) {
  .header {
    background-color: #fff;
    padding: 20px;
  }

  .header h1 {
    font-size: 0.9em;
  }

  .header p {
    font-size: 0.8em;
    color: #555;
  }

  .art-panel {
    width: 100%;
    padding: 20px;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    display: flex;
  }

  .top-img {
    width: 100%;
  }

  .image-text-section {
    display: flex;
    align-items: center;
    padding: 20px;
    margin: 20px 0;
  }

  .image-container {
    flex: 1;
    max-width: 50%;
    display: flex;
    justify-content: center;
  }

  .image-container img {
    max-width: 100%;
    height: auto;
    border-radius: 10px;
  }

  .text-container {
    flex: 1;
    padding-left: 20px;
  }

  .text-container h2 {
    font-size: 0.9em;
    font-weight: bold;
    color: #333;
  }

  .text-container p {
    font-size: 0.8em;
    color: #666;
    margin-top: 10px;
  }

  .circular-text-section {
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
    margin: 20px 0;
    padding: 5px;
  }

  .circle {
    width: 140px;
    height: 140px;
    border: 2px solid #ddd;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    padding: 20px;
    position: relative;
    background-color: transparent;
    z-index: 1;
  }

  .circle p {
    font-size: 1.1em;
    color: #333;
    line-height: 1.4em;
  }

  .circle+.circle {
    margin-left: -20px;
  }

  .price-panel {
    width: 100%;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    margin-top: 20px;
    /* padding: 20px; */
    font-size: 0.8em;
    color: #666;
    margin-top: 10px;
  }

  .price-img {
    width: 100%;
  }

  .order-button-container {
    width: 100%;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    padding: 15px;
  }

  .order-button-container img {
    cursor: pointer;
    width: 100%;
  }

  .color-selection-panel {
    width: 100%;
    padding: 10px;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    display: flex;
  }

  .color-selection-img {
    width: 100%;
  }

  .custom-message-section {
    text-align: center;
    padding: 20px;
  }

  .custom-message-section h2 {
    font-size: 24px;
    margin-bottom: 10px;
  }

  .custom-message-section p {
    font-size: 16px;
    margin: 10px 0;
  }

  .custom-message-container {
    width: 100%;
    margin: 10px 0;
    border-top: 1px solid lightgray;
    border-bottom: 1px solid lightgray;
  }

  .custom-message-img {
    margin: 20px 0;
    width: 40%;
    height: auto;
  }

  .custom-message-content {
    margin: 20px 0;
  }

  .custom-message-details {
    font-size: 18px;
    font-weight: bold;
  }

  .custom-message-info {
    display: flex;
    justify-content: space-between;
    padding: 0 20vw;
    margin: 10px 0;
  }

  .custom-message-info .name,
  .custom-message-info .birthdays {
    color: orange;
    font-weight: bold;
    font-size: 18px;
  }

  .custom-message-info .name {
    text-align: left;
  }

  .custom-message-info .birthdays {
    text-align: right;
  }

  .custom-message-detail p {
    text-align: left;
    margin-left: 30px;
  }

  .size-selection-section {
    text-align: center;
    padding: 15px;
  }

  .size-selection-section h2 {
    font-size: 24px;
    margin: 10px 0px;
  }

  .size-selection-section p {
    font-size: 16px;
    margin: 10px 0;
  }

  .size-selection-img {
    margin-bottom: 20px;
    width: 100%;
    height: auto;
  }

  .video-section {
    display: flex;
    align-items: center;
    padding: 20px;
    margin: 20px 0;
  }

  video {
    flex: 1;
    max-width: 50%;
    display: flex;
    justify-content: center;
  }

  .video-container img {
    max-width: 100%;
    height: auto;
    border-radius: 10px;
  }

  .video-container {
    flex: 1;
    padding-left: 20px;
  }

  .video-container h2 {
    font-size: 0.9em;
    font-weight: bold;
    color: #333;
  }

  .safety-section {
    display: flex;
    align-items: center;
    padding: 20px;
    margin: 20px 0;
  }

  .safety-container {
    flex: 1;
    max-width: 50%;
    display: flex;
    justify-content: center;
  }

  .safety-container img {
    max-width: 60%;
    height: auto;
    border-radius: 10px;
  }

  .safety-container {
    flex: 1;
    padding-left: 20px;
  }

  .safety-container h2 {
    font-size: 0.9em;
    font-weight: bold;
    color: #333;
  }

  .safety-container p {
    font-size: 0.8em;
    color: #666;
    margin-top: 10px;
  }

  .order-flow-section {
    text-align: center;
    margin-top: 50px;
  }

  .order-flow-section h2 {
    font-size: 32px;
    font-weight: bold;
  }

  .order-flow-section h3 {
    font-size: 24px;
    margin-bottom: 10px;
  }

  .order-flow-section hr {
    width: 80%;
    margin: 10px auto;
    border: none;
    border-top: 1px solid #000;
  }

  .flow-text {
    width: 60vw;
    margin: 10px 0px;
  }

  .flow-step {
    display: flex;
    align-items: center;
    margin: 10px 0;
    padding: 10px;
  }

  .flow-step-image {
    flex: 1;
  }

  .flow-step-image img {
    padding: 20px;
    width: 50vw;
    height: auto;
  }

  .flow-step-content {
    flex: 2;
    text-align: left;
  }

  .flow-step-content h4 {
    font-size: 28px;
    font-weight: bold;
  }

  .flow-step-content p {
    font-size: 0.8em;
    line-height: 1.5;
  }

  .flow-step-content .time-frame {
    display: inline-block;
    background-color: black;
    color: white;
    padding: 5px 10px;
    font-size: 0.9em;
    margin-top: 10px;
    border-radius: 5px;
  }

  .contact-form {
    max-width: 90%;
    margin: 0 auto;
    padding: 20px;
    font-family: Arial, sans-serif;
  }

  .contact-form h2 {
    font-size: 1.2em;
    font-weight: bold;
    margin-bottom: 10px;
  }

  .required-note {
    font-size: 0.9em;
    color: #555;
    margin-bottom: 20px;
  }

  .form-group {
    margin-bottom: 20px;
  }

  .form-group label {
    display: block;
    font-weight: bold;
    margin-bottom: 5px;
  }

  .form-group input[type="text"],
  .form-group input[type="email"],
  .form-group textarea {
    width: 100%;
    padding: 15px;
    border-radius: 20px;
    border: 1px solid #ddd;
    background-color: #f7f7f7;
    font-size: 0.8em;
  }

  .form-group textarea {
    resize: none;
  }

  .upload-area {
    border: 2px dashed #ddd;
    border-radius: 20px;
    padding: 20px;
    text-align: center;
    background-color: #f7f7f7;
    position: relative;
  }

  .upload-button {
    cursor: pointer;
    font-weight: bold;
    background-color: #333;
    color: #fff;
    padding: 10px 20px;
    border-radius: 10px;
    display: inline-block;
    margin-top: 10px;
  }

  .submit-button {
    background-color: #333;
    color: #fff;
    font-size: 0.9em;
    border: none;
    border-radius: 30px;
    padding: 15px 30px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
  }

  .submit-button:hover {
    background-color: #555;
  }

  .material-quality-section {
    text-align: left;
    margin-top: 50px;
    padding: 5vw;
  }

  .material-quality-section h2 {
    font-size: 0.9em;
    font-weight: bold;
    margin-bottom: 20px;
  }

  .quality-items {
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
  }

  .quality-item {
    width: 48%;
    text-align: left;
    box-sizing: border-box;
    margin: 10px 0px;
  }

  .quality-item img {
    width: 100%;
    height: auto;
    margin-bottom: 15px;
  }

  .quality-item h3 {
    font-size: 0.9em;
    font-weight: bold;
    margin-bottom: 10px;
  }

  .quality-item p {
    font-size: 14px;
    color: #555;
  }

  .order-button-img {
    margin: 30px 0px;
    width: 60%;
  }

  #footer {
    margin-bottom: 1vh;
    padding: 20px;
    width: 100%;
  }

  #footer p {
    font-size: 10px;
    color: #555;
  }
}